<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="form formMarginBtm20">
        <FormItem>
          <span>客户名称：</span>
          <Select class="iviewIptWidth250 marginRight50" v-model="supplierIndex" clearable filterable>
            <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>经手人：</span
          ><Select class="iviewIptWidth250 marginRight50" v-model="queryFrom.handled_by" clearable filterable>
            <Option v-for="item in handledByList" :value="item.id" :key="item.id">{{ item.user_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>创建时间：</span>
          <DatePicker class="iviewIptWidth250" placeholder="请选择" format="yyyy-MM-dd" @on-change="changeTime($event, 1)" clearable></DatePicker>
          <span class="choseTimeZhi">至</span>
          <DatePicker :options="options" class="iviewIptWidth250" format="yyyy-MM-dd" clearable placeholder="请选择" @on-change="changeTime($event, 2)"></DatePicker>
        </FormItem>
        <br />
        <FormItem>
          <span class="margintLeft16">审核人：</span
          ><Select class="iviewIptWidth250 marginRight45" v-model="queryFrom.examine_by" clearable filterable>
            <Option v-for="item in examineByList" :value="item.id" :key="item.id">{{ item.user_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>厂家名称：</span>
          <Select filterable clearable v-model="queryFrom.factory_id" class="iviewIptWidth250">
            <Option v-for="item in nameList" :value="item.subfactory_id" :key="item.subfactory_id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="10">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="pageBtn finger btnReset marginLeft20" @click="addOrder" v-if="insertAble">新增</span>
          <span class="longTextBtn finger btnReset marginLeft20" @click="output" :loading="Loagin">批量导出</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}({{ item.num }})
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table :columns="listColumns" :data="listData" border :loading="isLoad">
        <template slot-scope="{ row }" slot="product_name_cut">
          <Poptip word-wrap width="200" :content="row.product_name_str" trigger="hover" placement="top">
            <span>{{ row.product_name_cut }}</span>
          </Poptip>
        </template>
      </Table>
      <div class="tabPages" v-if="listData.length > 0 && total">
        <Page  :total="total" :current="queryFrom.page" @on-change="changePage" class="fl" />
        <div class="fr" style="font-size: 14px;">共计{{ total }}条记录</div>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData.length == 0 && !isLoad">
        <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
      </div>
    </div>
    <!--    查看驳回原因-->
    <Modal v-model="reviewReasonStatus" label-position="left" width="600" :mask-closable="false" :footer-hide="true" class-name="vertical-center-modal" title="驳回原因">
      <div class="reason clearfix">
        <div class="item">
          <div>驳回原因：</div>
          <div>{{ reviewReasonInfo.reject_reason }}</div>
        </div>
        <div class="item">
          <div style="margin-left: 14px;">审核人：</div>
          <div>{{ reviewReasonInfo.examine_by }}</div>
        </div>
      </div>
      <div class="foot"></div>
    </Modal>
    <!--    审核-->
    <Modal v-model="reviewStatus" label-position="left" width="400" title="审核" :footer-hide="true" class-name="vertical-center-modal">
      <div>
        <div class="clearfix" style="height: 30px; line-height: 30px; display: flex; align-content: center;">
          <span class="fl" style="margin-right: 40px;">审核结果：</span
          ><RadioGroup v-model="postReview.examine_result" class="fl">
            <Radio label="1" style="margin-right: 40px;">通过</Radio>
            <Radio label="2">驳回</Radio>
          </RadioGroup>
        </div>
        <div class="reasonDiv">
          <div v-if="postReview.examine_result == 2"><span class="fl">驳回原因：</span><i-input class="fl ipt" placeholder="请输入驳回原因" v-model.trim="postReview.reject_reason"></i-input></div>
        </div>
        <div class="foot clearfix">
          <span class="pageBtn finger btnSure fr marginLeft20" @click="sureReview">确定</span>
          <span class="pageBtn finger btnCancle fr" @click="reviewStatus = false">取消</span>
        </div>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TipsComponent from '@/components/tipsComponent'
export default {
  name:'quotationConsign',
  components: {
    TipsComponent,
  },
  computed: {
    ...mapState(['clientNameList']),
  },
  data() {
    return {
      Loagin: false,
      nameList: [],
      options: {},
      deleteStatus: false,
      queryFrom: {
        factory_id: '',
        page: 1,
        rows: 10,
        customer_type: '',
        customer_id: '',
        handled_by: '',
        examine_by: '',
        begin_date: '',
        end_date: '',
        dimension: '',
      },
      isLoad: true,
      supplieList: [],
      supplierIndex: '-1',
      navFrist: [
        {
          name: '全部',
          num: 0,
          type: '-1',
        },
        {
          name: '待审核',
          num: 0,
          type: 0,
        },
        {
          name: '审核中',
          num: 0,
          type: 2,
        },
        {
          name: '审核通过',
          num: 0,
          type: 1,
        },
        {
          name: '审核驳回',
          num: 0,
          type: 3,
        },
      ],
      clickIndex: '-1',
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 50,
        },
        {
          title: '单号',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.goDetails(param.row, 2)
                    },
                  },
                },
                param.row.quotation_code
              ),
            ])
          },
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          minWidth: 250,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.customer_name
              ),
            ])
          },
        },
        {
          title: '产品名称',
          slot: 'product_name_cut',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '经手人',
          key: 'handled_by',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '创建时间',
          key: 'insertTime',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '审核人',
          key: 'examine_by_str',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '审核时间',
          key: 'examineTime',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '备注',
          key: 'remark',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.remark
              ),
            ])
          },
        },
        {
          title: '状态',
          key: 'quotation_status',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '操作',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            if (param.row.quotation_status == '待审核' || param.row.quotation_status == '审核中') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      // marginRight: '10px',
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: param.row.quotation_status == '审核中',
                    },
                    on: {
                      click: () => {
                        this.EditOrder(param.row)
                      },
                    },
                  },
                  '编辑'
                ),
                h(
                  'span',
                  {
                    style: {
                      // marginRight: '10px',
                      marginLeft: param.row.quotation_status == '审核中' ? '0px' : '10px',
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.review(param.row)
                      },
                    },
                  },
                  '审核'
                ),
                h(
                  'span',
                  {
                    style: {
                      marginLeft: '10px',
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: param.row.quotation_status == '审核中',
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
              ])
            } else if (param.row.quotation_status == '审核驳回') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      // marginRight: '10px',
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      // disableUse: this.modifyAble,
                    },
                    on: {
                      click: () => {
                        this.EditOrder(param.row)
                      },
                    },
                  },
                  '编辑'
                ),
                h(
                  'span',
                  {
                    style: {
                      marginLeft: '10px',
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    class: {
                      disableUse: this.deleteAble,
                    },
                    on: {
                      click: () => {
                        this.deleteRow(param.row)
                      },
                    },
                  },
                  '删除'
                ),
                h(
                  'span',
                  {
                    style: {
                      marginLeft: '10px',
                      color: '#389AFC',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.reviewReason(param.row)
                      },
                    },
                  },
                  '驳回原因'
                ),
              ])
            } else if (param.row.quotation_status == '审核通过') {
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#389AFC',
                      // marginRight: '10px',
                      cursor: 'pointer',
                    },
                    on: {
                      click: () => {
                        this.saleExport(param.row.quotation_code)
                      },
                    },
                  },
                  '导出'
                ),
              ])
            }
          },
        },
      ],
      listData: [],
      total: 1,
      handledByList: [], // 经手人
      examineByList: [], // 审核人
      reviewReasonInfo: {}, // 驳回原因
      reviewStatus: false,
      reviewReasonStatus: false,
      postReview: {
        quotation_code: '',
        examine_result: '',
        reject_reason: '',
      },
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      this.querySupplierSelect()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  mounted() {
    this.querySupplierSelect()
    this.querySpecialUser(1)
    this.querySpecialUser(2)
    this.getRightVisibal()
    this.getListSupList()
  },
  methods: {
    output() {
      if (this.supplierIndex >= 0) {
        this.queryFrom.customer_id = this.supplieList[this.supplierIndex].customer_id
        this.queryFrom.customer_type = this.supplieList[this.supplierIndex].customer_type
      } else {
        this.queryFrom.customer_id = ''
        this.queryFrom.customer_type = ''
      }
      this.$http.downFile(this.$apiConsign.quotationExportBatch, this.queryFrom, true).then(res => {
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/msword' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '报价单总表.doc'
        aLink.click()
      })
    },
    getListSupList() {
      this.$http.get(this.$apiConsign.factoryListBusiness, true).then(res => {
        this.nameList = res.data
      })
    },
    saleExport(code) {
      this.$http.downFile(this.$apiConsign.saleQuotationExport, { quotation_code: code }).then(res => {
        // let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        let blob = new Blob([res.data], { type: 'application/msword' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '报价单' + code + '.doc'
        aLink.click()
      })
    },
    // 确认删除
    sureDelete() {
      this.$http.DeleteAll(this.$apiConsign.saleQuotationOperate, { quotation_code: this.postReview.quotation_code }, true).then(res => {
        this.query()
        this.deleteStatus = false
      })
    },
    // 点击删除
    deleteRow(row) {
      this.postReview.quotation_code = row.quotation_code
      this.deleteStatus = true
    },
    // 确认删除
    sureReview() {
      if (!this.postReview.examine_result) {
        this.$Message.warning('请选择审核结果')
        return
      }
      if (this.postReview.examine_result == 2 && !this.postReview.reject_reason) {
        this.$Message.warning('请填写驳回原因')
        return
      }
      this.$http.post(this.$apiConsign.saleQuotationOperate, this.postReview, true).then(res => {
        this.reviewStatus = false
        this.$Message.success('审核成功')
        this.query()
      })
    },
    // 点击审核
    review(row) {
      this.postReview = {
        quotation_code: row.quotation_code,
        examine_result: '',
        reject_reason: '',
      }
      this.reviewStatus = true
    },
    // 查看驳回原因
    reviewReason(row) {
      this.$http.get(this.$apiConsign.saleQuotationQejectReason, { quotation_code: row.quotation_code }, false).then(res => {
        this.reviewReasonInfo = res.data
        this.reviewReasonStatus = true
      })
    },
    // 查询经手人和审核人
    querySpecialUser(num) {
      if (num == 1) {
        this.$http.get(this.$apiConsign.publicSpecialUser, { user_type: num }, true).then(res => {
          this.handledByList = res.data
        })
      } else {
        this.$http.get(this.$apiConsign.publicSpecialUser, { user_type: num }, true).then(res => {
          this.examineByList = res.data
        })
      }
    },
    // 查询客户名称列表
    querySupplierSelect() {
      this.$http
        .fetch(this.$apiConsign.customerList, true)
        .then(res => {
          this.supplieList = res.data
        })
        .then(() => {
          this.query()
        })
    },
    // 点击查询
    query() {
      this.queryFrom.page = 1
      this.queryList()
    },
    // 查询列表.
    queryList() {
      this.isLoad = true
      if (this.supplierIndex >= 0) {
        this.queryFrom.customer_id = this.supplieList[this.supplierIndex].customer_id
        this.queryFrom.customer_type = this.supplieList[this.supplierIndex].customer_type
      } else {
        this.queryFrom.customer_id = ''
        this.queryFrom.customer_type = ''
      }
      this.$http.get(this.$apiConsign.saleQuotationList, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.total = res.total
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].insertTime = this.$moment(res.data[i].insert_time * 1000).format('YYYY-MM-DD')
          res.data[i].examineTime = res.data[i].examine_time ? this.$moment(res.data[i].examine_time * 1000).format('YYYY-MM-DD') : ''
        }
        this.listData = res.data
        for (let i = 0; i < res.tab_count.length; i++) {
          this.navFrist[i].num = res.tab_count[i]
        }
      })
    },
    // 时间改变
    changeTime(e, num) {
      let that = this
      if (num == 1) {
        that.queryFrom.begin_date = e
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.queryFrom.begin_date) - 86400000
          },
        }
      } else {
        that.queryFrom.end_date = e
      }
    },
    goDetails(row, num) {
      this.$router.push({
        path: '/quotationDetailsConsign',
        query: {
          id: row.quotation_code,
          num: 2,
        },
      })
    },
    addOrder() {
      this.$router.push({ path: '/quotationAddConsign' })
    },
    EditOrder(row) {
      this.$router.push({
        path: '/quotationAddConsign',
        query: {
          id: row.quotation_code,
        },
      })
    },
    changePage(e) {
      this.queryFrom.page = e
      this.queryList()
    },
    clickNav(type) {
      this.clickIndex = type
      this.queryFrom.dimension = this.clickIndex >= 0 ? this.clickIndex + '' : ''
      this.queryFrom.page = 1
      this.queryList()
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
  },
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  color: #000;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 22px;
  }
  .topDiv {
    padding: 20px 22px 0 22px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
        margin-left: 32px;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight50 {
  margin-right: 60px;
}
.marginRight45 {
  margin-right: 45px;
}
.reason {
  font-size: 14px;
  .item {
    margin-bottom: 20px;
    display: flex;
    div:nth-child(1) {
      font-weight: 600;
      margin-right: 5px;
    }
    div:nth-child(2) {
      flex: 1;
    }
  }
}
.tabPages {
  margin-top: 25px;
  overflow: hidden;
  line-height: 32px;
}
.reasonDiv {
  margin: 20px 0;
  min-height: 40px;
  div {
    display: flex;
    height: 36px;
    line-height: 36px;
    .ipt {
      flex: 1;
    }
  }
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
.margintLeft16 {
  margin-left: 16px;
}
.marginLeft10 {
  margin-left: 10px;
}
/deep/ .ivu-table-cell-slot {
  text-align: left;
}
</style>
